import Color from "colorjs.io";

export const useColors = () => {
  const colorMode = useColorMode();

  function setLuminance(color, options) {
    if (!color) {
      return "#000000";
    }

    const clr = new Color(color);
    clr.set({
      "lch.l": options?.luminance || (colorMode.value == "light" ? 47 : 27),
    });
    return clr.toString(options?.precision || 5, options?.format || "hex");
  }

  return {
    setLuminance,
  };
};
